$lightTextColor: #fff;
$darkTextColor: #212529;

// Меню
$menuTextSize: 0.85rem;
$menuTextColor: #212529;

// Бургер
$menuBurgerTextColor: #f7f7f7;
$menuBurgerBgColor: #212529;

// Фирменые цвета
$cpuBlue: #00bad5;
$cpuBlueDark: #0092a6;
$cpuBlueLight: rgba(0, 186, 213, 0.42);
$cpuPink: #f53bb9;
$cpuPinkDark: #dc239f;
$cpuPinkLight: #ffb3e7ab;