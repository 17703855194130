.request-container {
  position: relative;
  overflow: hidden;

  .request {
    padding: 50px 0;

    .request__title {
      color: $darkTextColor;
    }

    .request__body {
      position: relative;
      display: flex;

      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 85%);
        background: $cpuBlue;
        width: 100vw;
        height: 150px;
        z-index: 1;
      }

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        clip-path: polygon(0 0%, 100% 15%, 100% 85%, 0 100%);
        background: $cpuBlueLight;
        width: 100vw;
        height: 150px;
        z-index: 1;
      }

      .request__car {
        display: flex;
        flex: 1 1 50vw;
        width: 540px;
        height: 220px;
        background-image: url("images/request_car.png");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        z-index: 2;
      }

      .request__form {
        display: flex;
        flex: 1 1 50vw;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 2;

        .faq-link {
          color: $lightTextColor;
          margin: 15px 0 -15px 0;

          &:hover {
            color: $cpuPink;
          }
        }
      }
    }
  }
}