.report-container {
  position: relative;
  margin-top: 70px;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    clip-path: polygon(0 0%, 100% 20%, 100% 100%, 0% 100%);
    background: linear-gradient(180deg, #F5F5F5 0%, rgba(245, 245, 245, 0) 100%);
    width: 100vw;
    height: 70vh;
    z-index: 1;
  }

  .report {
    position: relative;
    padding: 50px 0;
    z-index: 2;
    display: flex;
    align-items: center;
    gap: 50px;

    @media screen and (max-width: 630px) {
      flex-direction: column;
      gap: 15px;
    }

    .left {
      flex: 0 0 50%;
      text-align: right;

      @media screen and (max-width: 630px) {
        order: 1;
      }

      h1 {
        color: $darkTextColor;
      }

    }

    .right {
      position: relative;
      display: flex;
      flex: 0 0 50%;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: 630px) {
        order: 0;
      }

      img {
        height: 418px;
        width: auto;
        @media screen and (max-width: 900px) {
          height: 350px;
        }

        @media screen and (max-width: 630px) {
          height: 300px;
          transform: translate(20%,0);
        }

        @media screen and (max-width: 560px) {
          height: 250px;
        }
      }
    }
  }

}