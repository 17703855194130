.whatif-container {
  position: relative;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    clip-path: polygon(0 20%, 100% 0, 100% 100%, 0% 100%);
    background: linear-gradient(180deg, #F5F5F5 0%, rgba(245, 245, 245, 0) 100%);
    width: 100vw;
    height: 70vh;
    z-index: 1;
  }

  .whatif {
    position: relative;
    padding: 150px 0 50px 0;
    z-index: 2;

    @media screen and (max-width: 600px) {
      padding: 150px 0 0px 0;
    }

    .whatif__title {
      color: $darkTextColor;
    }

    .whatif__subtitle {
      text-align: center;
      font-family: 'Gilroy-Bold', sans-serif;
      color: $darkTextColor;
      text-transform: uppercase;
      padding: 50px 0;
      font-size: 1.5rem;
      @media screen and (max-width: 600px) {
        padding: 50px 0 0 0;
      }
    }

    .whatif__blocks {
      display: flex;

      @media screen and (max-width: 900px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
      }

      @media screen and (max-width: 600px) {
        display: grid;
        grid-template-columns: 1fr;
        gap: 15px;
      }

      .whatif__block {
        position: relative;
        box-sizing: border-box;
        padding: 30px 15px 30px 30px;
        margin: auto;
        height: 160px;
        min-width: 200px;
        max-width: 250px;
        width: calc(25% - 30px);
        display: flex;
        align-items: center;

        @media screen and (max-width: 900px) {
          min-width: 200px;
          max-width: 100%;
          width:100%;
        }

        @media screen and (max-width: 600px) {
          height: auto;
        }


        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          transform: skewX(-12deg); /*Поворачиваем блок*/
          background: #fff;
          box-shadow: 0 20px 27px rgba(0, 0, 0, 0.05);
          z-index: 1;
          border-radius: 25px;
        }

        p {
          margin: 0;
          font-family: 'Gilroy-Bold', sans-serif;
          text-align: left;

          line-height: 1.5;
          font-size: 1rem;
          text-transform: uppercase;
          position: relative;
          z-index: 2;
        }

      }
    }
  }

}