@font-face {
  font-family: "Gilroy-Light";
  src: url("fonts/Gilroy-Light.woff");
}

@font-face {
  font-family: "Gilroy";
  src: url("fonts/Gilroy-Regular.woff");
}

@font-face {
  font-family: "Gilroy-Medium";
  src: url("fonts/Gilroy-Medium.woff");
}

@font-face {
  font-family: "Gilroy-Semibold";
  src: url("fonts/Gilroy-Semibold.woff");
}

@font-face {
  font-family: "Gilroy-Bold";
  src: url("fonts/Gilroy-Bold.woff");
}

