@import "scss/vars";
@import "scss/base";
@import "scss/fonts";
@import "scss/faq";
@import "scss/mmenu";
@import "scss/modal";
@import "scss/checkbox";
@import "scss/select";

@import "scss/blocks/header";
// Main
main {
  position: relative;
  @import "scss/blocks/welcome";
  @import "scss/blocks/whoweare";
  @import "scss/blocks/stat";
  @import "scss/blocks/request";
  @import "scss/blocks/whatwecheck";
  @import "scss/blocks/report";
  @import "scss/blocks/whatif";
  @import "scss/blocks/carselection";
  @import "scss/blocks/howitworks";
  @import "scss/blocks/ourservice";
  @import "scss/blocks/checkedcars";
  @import "scss/blocks/reviews";
  @import "scss/blocks/youtube";
  @import "scss/blocks/lastrequest";
}
@import "scss/blocks/footer";
@import "scss/yt-lite";