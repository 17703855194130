.stat-container {
  position: relative;
  overflow: hidden;
  padding: 50px 0;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    clip-path: polygon(0 20%, 100% 0, 100% 100%, 0% 100%);
    background: linear-gradient(180deg, #F5F5F5 0%, rgba(245, 245, 245, 0) 100%);
    width: 100vw;
    height: 70vh;
    z-index: 1;
  }

  .stat {
    position: relative;
    padding: 150px 0 50px 0;
    z-index: 2;

    h1 {
      color: $darkTextColor;
    }

    .stat__blocks {
      display: flex;


      .stat__block {
        position: relative;
        box-sizing: border-box;

        padding: 20px;
        display: block;
        margin: auto;
        min-width: 200px;
        max-width: 250px;
        width: calc(25% - 30px);

        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          transform: skewX(-12deg); /*Поворачиваем блок*/
          background: #fff;
          box-shadow: 0 20px 27px rgba(0, 0, 0, 0.05);
          z-index: 1;
          border-radius: 25px;
        }


        .stat__block_digit {
          font-family: "Gilroy-Bold";
          text-align: center;
          font-size: 2rem;
          text-transform: uppercase;
          color: #00BAD5;
          position: relative;
          z-index: 3;
        }

        .stat__block_split {
          text-align: center;
          margin: 0 auto;
          width: 80%;
          height: 2px;
          background: #ED5CBF;
          position: relative;
          z-index: 3;
        }

        .stat__block_description {
          margin-top: 10px;
          text-align: center;
          font-family: "Gilroy-Semibold";
          font-size: 1rem;
          text-transform: uppercase;
          color: $darkTextColor;
          position: relative;
          z-index: 3;
        }
      }
    }
  }

}