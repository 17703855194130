.howitworks-container {
  overflow: hidden;

  .howitworks {
    margin: 150px 0 100px 0;
    text-align: center;

    .howitworks__block {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 40%;
        height: auto;
        transform: translateX(-20px);
        @media screen and (max-width: 700px){
          width: 70%;
        }
      }

      img {
        position: relative;
        width: 60%;
        height: auto;
        @media screen and (max-width: 700px){
          display: none;
        }
      }
    }
  }

}