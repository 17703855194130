@charset "UTF-8";

*, *::before, *::after {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
}

html {
  font-size: 15px;
}

html * {
  max-height: 99999px;
}

html, body {
  height: 100%;
  position: relative;
}

body {
  background-color: transparent;
  font-size: 1rem;
  line-height: 1.625;
  min-width: 320px;
  overflow-x: hidden;
  color: $darkTextColor;
  font-family: 'Gilroy', sans-serif;
  letter-spacing: .02rem;
  font-weight: normal;
  font-style: normal;
  padding: 70px 0 0;
}

a {
  text-decoration: none;
  color: $darkTextColor;

  &:hover {
    color: $cpuBlue;
  }
}

a,
a::before,
a::after,
img,
path,
button,
button::before,
button::after,
.btn,
label,
input,
::-webkit-input-placeholder,
.nav {
  transition: all .2s ease-in-out;
}

ul, ol {
  list-style: none;
}

a img {
  border: none;
}

h1 {
  font-family: Gilroy-Bold, sans-serif;
  font-size: 1.9rem;
  line-height: 1.5;
  margin-bottom: 50px;
  text-transform: uppercase;
  color: #212529;
}


input, textarea, button, select {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  color: inherit;
}

input {
  background-color: #fff;
  color: $darkTextColor;
  border: 1px solid #0000001f;
  border-radius: 8px;
  height: 40px;
  width: 100%;
  line-height: 40px;
  font-size: 1rem;
  display: block;
  padding: .68rem 25px .4rem 12px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.input-lg {
  background-color: #fff;
  color: $darkTextColor;
  border: 1px solid #0000001f;
  border-radius: 8px;
  height: 50px;
  width: 100%;
  line-height: 40px;
  font-size: 1.5rem;
  display: block;
  padding: 0 25px 0 12px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.textarea {
  background-color: #fff;
  color: $darkTextColor;
  border: 1px solid #0000001f;
  border-radius: 8px;
  //height: 50px;
  width: 100%;
  line-height: 40px;
  font-size: 1.5rem;
  display: block;
  padding: 0 25px 0 12px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}


button {
  border: none;
  cursor: pointer;
  background-color: transparent;
  display: inline-block;
  vertical-align: middle;
}

.rub_suffix {
  position: relative;

  &:after {
    content: "₽";
    display: block;
    width: 15px;
    height: 20px;
    position: absolute;
    right: 5px;
    top: 9px;
    font-size: 1.1rem;
    z-index: 5;
  }
}

.splitter {
  width: 70%;
  height: 1px;
  background: rgba(0, 0, 0, 0.12);
  margin: 30px auto 120px auto;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify
}

.text-uppercase {
  text-transform: uppercase;
}

.btn {
  cursor: pointer;
  white-space: nowrap;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 600;
  text-align: center;
  user-select: none;
  border-radius: 3px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  letter-spacing: .05rem;
  border: 1px solid #eee;
  background: $lightTextColor;
  color: $darkTextColor;

  img {
    margin-right: 5px;
  }

  &:hover {
    background: $cpuPink;
    color: $lightTextColor;
    border: 1px solid $cpuPink;
  }
}

.btn--icon {
  width: 40px;
  height: 40px;
  padding: 0;
  font-size: 2rem;
  border-radius: 8px;

  &:hover {
    background: $lightTextColor;
    color: $darkTextColor;
    border: 1px solid $cpuPink;
  }
}


.btn--sm {
  padding-bottom: .4375rem;
  padding-top: .55rem;
  font-size: .875rem;
  border-radius: 26px;
}

.btn--md {
  padding-top: 1rem;
  padding-bottom: .9375rem;
  min-width: 14.6875rem;
  border-radius: 26px;
}

.btn--lg {
  padding-top: 1.125rem;
  padding-bottom: 1.0625rem;
  min-width: 16.8125rem;
  border-radius: 2rem;


  @media screen and (max-width: 630px) {
    padding-top: 0.85rem;
    padding-bottom: 0.65rem;
    min-width: 10rem;
    border-radius: 2rem;
  }
}

.btn--uppercase {
  text-transform: uppercase;
}

.btn--fluid {
  min-width: 100%;
}

.btn--radius {
  border-radius: 2rem;
}

.btn--pink {
  background: $cpuPink;
  color: $lightTextColor;
  border: 1px solid $cpuPink;

  &:hover {
    background: $cpuPinkDark;
    color: $lightTextColor;
    border: 1px solid $cpuPink;
  }
}

.btn--blue {
  background-color: $cpuBlue;
  color: $lightTextColor;
  border: 1px solid $cpuBlue;

  &:hover {
    background: $cpuBlueDark;
    color: $lightTextColor;
    border: 1px solid $cpuBlue;
  }
}

.form-control {
  margin-top: 16px;
}

.pink {
  color: $cpuPink
}

.blue {
  color: $cpuBlue
}

.carpickup {
  position: relative;
  display: inline-block;
  width: 150px;
  height: 35px;
  transform: translateY(33%);
  background: url("images/carpickup.svg") no-repeat center;
  background-size: contain;
}

.grid {
  display: grid;
}

.grid-4 {
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  column-gap: 30px;
}

.grid-3 {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  column-gap: 30px;
}

.grid-2 {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows:  1fr;
  gap: 15px;
}

.gap-30 {
  gap: 3vw;
}

.mt-30 {
  margin-top: 30px;
}


.mb-15 {
  margin-bottom: 20px;
}

.mr-15 {
  margin-right: 20px;
}

// Swiper
.swiper-container {
  position: relative;
  padding-bottom: 50px;
  max-width: 850px;
  margin: 0 auto;


  .swiper {
    position: relative;
    z-index: 3;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 30px 15px;


    .swiper-wrapper {

      .swiper-slide {

        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;

        .btn {
          margin-top: 30px;
          position: relative;
          z-index: 2;
        }

        .slide-body {
          position: relative;
          z-index: 3;

          border-radius: 7px;

          .slide-title {
            font-family: 'Gilroy-SemiBold', sans-serif;
            margin-top: 10px;
            text-align: left;
          }

          iframe, img {
            width: auto;
            //border: 10px solid $cpuBlue;
            border-radius: 7px;
          }
        }
      }
    }
  }

  .swiper-pagination {


    .swiper-pagination-bullet {
      position: relative;
      border-radius: 2px;
      width: 20px;
      height: 15px;
      transform: skewX(-15deg); /*Поворачиваем блок*/

    }

    .swiper-pagination-bullet-active {
      background: $cpuPink;
    }

  }


}

.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
  color: $cpuPink;
}

.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
  color: $cpuPink;
}

.swiper-button-next {
  transform: translate(0, -75%) !important;
}

.swiper-button-prev {
  transform: translate(0, -75%) !important;
}


// Container
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}


@media (min-width: 1230px) {
  .container {
    max-width: 1200px;
  }
}

@media (max-width: 1230px) {
  .container {
    max-width: 1200px;
  }
}

@media (max-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (max-width: 576px) {
  .container {
    max-width: 540px;
  }
}


h1{
  @media screen and (max-width: 720px) {
    font-size: 1.5rem;
    margin-bottom: 30px;
  }
  @media screen and (max-width: 560px) {
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
}

h2{
  @media screen and (max-width: 640px) {
    font-size: 1rem;
  }
}

h3{
  @media screen and (max-width: 640px) {
    font-size: 0.8rem;
  }
}





