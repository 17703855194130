
.reviews-container {
  position: relative;
  overflow: hidden;

  .reviews {
    position: relative;
    text-align: center;

    .title {

    }


  }
}