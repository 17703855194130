.whoweare-container {
  overflow: hidden;

  .whoweare {
    position: relative;
    display: grid;
    grid-template-columns: 1.2fr 0.8fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0;
    grid-row-gap: 0;
    z-index: 2;
    padding: 50px 0;
    @media screen and (max-width: 640px) {
      grid-template-columns:1fr;
    }

    .left {
      text-align: center;
      @media screen and (max-width: 640px) {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      article {
        width: 500px;
        height: 280px;
        border: 10px solid $cpuBlue;
        border-radius: 25px;
        @media screen and (max-width: 900px) {
          width: 450px;
          height: 260px;
        }
        @media screen and (max-width: 830px) {
          width: 400px;
          height: 240px;
        }
        @media screen and (max-width: 730px) {
          width: 380px;
          height: 220px;
          border: 5px solid $cpuBlue;
        }
        @media screen and (max-width: 730px) {
          width: 350px;
          height: 200px;
        }
        @media screen and (max-width: 640px) {
          width: 95%;
          height: auto;
          min-width: 290px;
        }
      }
    }

    .right {
      display: flex;
      align-items: center;

      @media screen and (max-width: 640px) {
        text-align: center;
      }

      h2 {
        font-size: 1em;
      }
    }
  }
}