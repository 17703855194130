.checkedcars-container {
  position: relative;
  overflow: hidden;
  padding: 0;

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    clip-path: polygon(0 0, 100% 10%, 100% 90%, 0% 100%);
    background: #F5F5F5;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .checkedcars {
    position: relative;
    z-index: 2;
    padding: 70px 0;


    .title {

    }

    .checkedcars__swiper {

      width: 100%;
      height: 100%;
      padding: 0 70px;

      .swiper-wrapper {
        .swiper-slide {
          /* Center slide text vertically */
          display: -webkit-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          flex-direction: column;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          -webkit-justify-content: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
          padding: 60px 60px 110px 60px;

          &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: calc(-90px + 100%);
            z-index: 1;
            transform: skewX(-7deg); /*Поворачиваем блок*/
            background: #fff;
            box-shadow: 0 20px 27px rgba(0, 0, 0, 0.05);
            z-index: 1;
            border-radius: 15px;
          }

          .checkedcars__slide-images {
            position: relative;
            z-index: 2;
            text-align: center;

            img {
              width: 90%;
            }
          }

          .checkedcars__slide-info {
            position: relative;
            z-index: 2;

            .title {

            }

            .subtitle {

            }

            .params {


              .title {
                font-family: 'Gilroy-SemiBold', sans-serif;
              }

              .body {

              }
            }

            .result {

              .title {
                font-family: 'Gilroy-SemiBold', sans-serif;
              }

              .body {

              }
            }
          }

          .btn {
            margin-top: 30px;
            position: relative;
            z-index: 2;
          }
        }
      }

      .swiper-button-next {

      }

      .swiper-button-prev {

      }

      .swiper-pagination {
        .swiper-pagination-bullet {
          border-radius: 2px;
          width: 20px;
          height: 15px;
          transform: skewX(-15deg); /*Поворачиваем блок*/

        }

        .swiper-pagination-bullet-active {
          background: $cpuPink;
        }

      }
    }


  }
}