// Header
header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 900;
  //border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  min-width: 360px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.12);


  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 3;
  }

  .header__body {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 70px;

    .push {
      margin-left: auto;
    }

    .header__logo {
      position: relative;
      flex: 1 1 180px;
      overflow: hidden;
      z-index: 3;

      .header__logo-image {
        display: block;
        height: 30px;
      }

      .header__logo-subtitle {
        font-weight: bold;
        font-size: 0.9rem;
        line-height: 22px;
        color: #4B4B4B;
        margin: 0;
      }

    }

    .header__burger {
      position: relative;
      display: none;
      flex: 0 0 50px;
      z-index: 3;

      @media screen and (max-width: 800px) {
        display: block;
      }

      .header__close-icon {
        display: none;
      }

      img {
        width: 30px;
      }

    }

    .header__menu {
      position: relative;
      flex-wrap: wrap;
      display: flex;
      flex: 0 0 auto;
      margin-right: 15px;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      z-index: 3;

      @media screen and (max-width: 800px) {
        display: none;
      }

      .header__link {
        position: relative;
        display: flex;
        align-items: center;
        border-radius: 25px;
        padding: 6px 10px 4px 10px;
        font-family: 'Gilroy-Medium', sans-serif;
        font-size: $menuTextSize;
        line-height: $menuTextSize;
        text-transform: uppercase;
        color: $menuTextColor;

        &:hover {
          background-color: $cpuBlue;
          color: $lightTextColor;
        }


        .header__icon-youtube {
          display: inline-block;
          width: 29px;
          height: 20px;
          margin-top: -2px;
          margin-left: 5px;
          background-size: contain;
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml,%3Csvg width='29' height='20' viewBox='0 0 29 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M27.9704 3.12324C27.6411 1.89323 26.6745 0.926623 25.4445 0.597366C23.2173 1.78814e-07 14.2827 0 14.2827 0C14.2827 0 5.34807 1.78814e-07 3.12088 0.597366C1.89323 0.926623 0.924271 1.89323 0.595014 3.12324C-2.98023e-07 5.35042 0 10 0 10C0 10 -1.54972e-06 14.6496 0.597364 16.8768C0.926621 18.1068 1.89323 19.0734 3.12324 19.4026C5.35042 20 14.285 20 14.285 20C14.285 20 23.2197 20 25.4468 19.4026C26.6769 19.0734 27.6435 18.1068 27.9727 16.8768C28.5701 14.6496 28.5701 10 28.5701 10C28.5701 10 28.5677 5.35042 27.9704 3.12324Z' fill='%23FF0000'/%3E%3Cpath d='M11.4275 14.2854L18.8475 10.0004L11.4275 5.71533V14.2854Z' fill='white'/%3E%3C/svg%3E%0A");
        }
      }
    }

    .header__login {
      z-index: 3;

      .header__link {

        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        background: #00BAD5;
        border-radius: 25px;
        height: 35px;
        max-width: 170px;
        min-width: 50px;
        padding-left: 7px;
        padding-right: 7px;
        text-decoration: none;
        font-weight: 600;
        font-size: $menuTextSize;
        line-height: 1;
        text-transform: uppercase;

        &:hover {
          background-color: $cpuPink;
          color: $lightTextColor;
        }

        span {
          position: relative;
          margin-bottom: -3px;
        }

        img {
          margin-right: 4px;
        }
      }
    }
  }

}