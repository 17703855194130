
.mobile_menu {
  padding: 0;
  margin: 0
}

.mobile_menu_container, .mobile_menu_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}

.mobile_menu_container #close, .mobile_menu_overlay #close {
  position: absolute;
  top: 50%;
  right: 20%;
  transform: translate(50%, -50%);
  font-size: 50px;
  color: #fff;
  background: rgba(255, 255, 255, .8);
  width: 70px;
  height: 70px;
  text-align: center;
  line-height: 100px;
  border-radius: 100%
}

.mobile_menu_container #close img, .mobile_menu_overlay #close img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%
}

.mobile_menu_container, .mobile_menu_container ul li ul {
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s
}

.mobile_menu_overlay {
  display: none;
  cursor: pointer;
  z-index: 10200;
  background: rgba(0, 0, 0, .5)
}

.mobile_menu_container {
  transform: translateX(-300px);
  width: 300px;
  overflow: hidden;
  z-index: 10201;
  background: #fff;
  -webkit-box-shadow: 0 0 15px #00000045;
  box-shadow: 0 0 15px #00000045
}

.mobile_menu_container.loaded {
  transform: translateX(0)
}

.mobile_menu_container .mobile_menu_content {
  overflow: auto;
  max-height: 100%;
  padding-bottom: 30px
}

.mobile_menu_container .mobile_menu_content .logo {
  width: 200px;
  margin: 15px auto
}

.mobile_menu_container .mobile_menu_content .logo a {
  text-decoration: underline;
  color: #fff;
  width: 100%;
  height: 100%;
  display: block
}

.mobile_menu_container .mobile_menu_content .logo a p {
  color: #212529
}

.mobile_menu_container .mobile_menu_content .logo a img {
  width: 80%;
  height: auto;
  min-width: 100px;
  max-width: 170px
}

.mobile_menu_container .mobile_menu_content .logo a .logo-description {
  color: #d7d7d7;
  font-size: 12px;
  padding: 0;
  margin: 0 auto
}

.mobile_menu_container ul {
  margin: 0;
  padding: 0
}

.mobile_menu_container ul li {
  list-style: none
}

.mobile_menu_container ul li {
  a,button{
    display: block;
    padding: 9px 20px;
    line-height: 20px;
    font-size: 16px;
    color: #212529;
    text-decoration: none
  }

}

.mobile_menu_container ul li a.parent {
  padding-right: 50px;
  //background: url(images//arrow_right.svg) right 20px center/20px no-repeat #3884da
}

.mobile_menu_container ul li a.parent:hover {
  //background: url(images//arrow_right.svg) right 20px center/20px no-repeat #3c8de8
}

.mobile_menu_container ul li a.back {
  padding-left: 50px;
  //background: url(images//arrow_left.svg) left 20px center/20px no-repeat #00bad5;
  box-sizing: border-box;
  min-height: 50px
}

.mobile_menu_container ul li a.back:hover {
  //background: url(images//arrow_left.svg) left 20px center/20px no-repeat #00bad5
}

.mobile_menu_container ul li a:hover {
  background: #00bad5;
  color: #fff
}

.mobile_menu_container ul li ul {
  transform: translateX(300px);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00bad5;
  z-index: 2
}

.mobile_menu_container ul li ul.loaded {
  transform: translateX(0)
}

.mobile_menu_container ul li ul.activity {
  overflow-y: auto;
  overflow-x: hidden
}

@media screen and (max-width: 550px) {
  .mobile_menu_container #close, .mobile_menu_overlay #close {
    right: 15px;
    transform: translate(0, -50%);
    font-size: 40px;
    width: 60px;
    height: 60px
  }
}

@media (max-width: 320px) {
  .mobile_menu_container {
    width: 240px
  }
}
