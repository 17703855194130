footer {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    clip-path: polygon(0 0, 100% 20%, 100% 100%, 0% 100%);
    background: #505050;
    width: 100vw;
    height: 100%;
    z-index: 1;
  }

  .footer__content {
    position: relative;
    z-index: 3;
    padding: 70px 15px 30px 15px;
    color: $lightTextColor;


    a {
      display: block;
      padding: 3px;
      text-decoration: none;
      margin: 5px;
      color: $lightTextColor;

      &:hover {

        color: $cpuBlue;
      }
    }

    .footer__mail {
      color: $lightTextColor;
    }
  }
}