.carselection-container {


  .carselection {
    position: relative;
    margin-top: 150px;
    z-index: 2;

    .carselection__header {
      position: relative;
      margin-bottom: -40px;

      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        clip-path: polygon(0 0%, 100% 15%, 100% 85%, 0 100%);
        background-color: $cpuPink;
        //background-color: #e6e6e6;
        background-image: url("images//arrow_to_right.png");
        background-repeat: no-repeat;
        background-position: 4% center;
        background-size: auto 120%;
        width: 100vw;
        height: 120px;
        z-index: 2;
      }

      &:before {
        content: "";
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 85%);
        background-color: $cpuPinkLight;
        width: 100vw;
        height: 120px;
        z-index: 2;
      }


      .carselection__title {
        position: relative;
        text-align: center;
        color: $lightTextColor;
        z-index: 3;
        margin: 0;
      }

      p {
        font-family: 'Gilroy-Medium', sans-serif;
        position: relative;
        text-align: center;
        color: $lightTextColor;
        font-size: 1.3rem;
        z-index: 3;
      }
    }

    .carselection__body {
      position: relative;
      z-index: 1;
      display: flex;
      justify-items: center;
      flex-direction: column;
      padding: 100px 50px 50px 50px;
      border: 10px solid #eee;
      border-top: none;
      border-radius: 0 0 30px 30px;

      @media screen and (max-width: 500px) {
        padding: 100px 15px 60px 15px;
      }

      .carselection__act-title {
        position: relative;
        z-index: 3;
        font-family: 'Gilroy-Bold', sans-serif;
        text-align: center;
        font-size: 1.7rem;
        margin-bottom: 15px;
      }

      .carselection__car-selection-price-block {
        position: relative;
        z-index: 3;
        margin: 0 auto;
        display: inline-block;


        .carselection__car-selection-price {
          font-family: 'Gilroy-Bold', sans-serif;
          color: $cpuBlue;
          font-size: 1.7rem;

          @media screen and (max-width: 950px) {
            font-size: 1.4rem;
          }

          @media screen and (max-width: 500px) {
            font-size: 1.2rem;
          }
        }

        .carselection__car-selection-subprice {
          font-family: 'Gilroy-Bold', sans-serif;
          color: #676767;
          font-size: 0.9rem;
          text-align: left;
        }

      }

      .carselection__car-selection {
        position: relative;
        z-index: 3;

        .form-group {
          padding: 0;
          margin: 0;
        }

        .help-block {
          position: absolute;
          display: block;
          margin-top: 5px;
          margin-bottom: 10px;
          color: #737373;
        }

        .carselection__car-selection-main {
          position: relative;
          z-index: 3;
          margin: 50px 0;
          @media screen and (max-width: 500px) {
            margin: 15px 0;
          }

          .grid-3 {
            gap: 15px
          }

          .params {
            .grid-3 {
              gap: 15px
            }

            @media screen and (max-width: 950px) {
              .grid-3 {
                grid-template-columns: repeat(2, 1fr);
              }
            }
            @media screen and (max-width: 700px) {
              .grid-3 {
                grid-template-columns: repeat(1, 1fr);
              }
            }
          }


          .table-condensed > thead > tr > th, .table-condensed > tbody > tr > th, .table-condensed > tfoot > tr > th, .table-condensed > thead > tr > td, .table-condensed > tbody > tr > td, .table-condensed > tfoot > tr > td {
            padding: 0;
          }

          .multiple-input-list__item {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr;
            gap: 0 30px;
            grid-template-areas: ". . .";
          }

          .select2-container--krajee-bs3 .select2-selection {
            border-radius: 8px;

          }

          .select-group {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows:  1fr;

            .select2-container--default .select2-selection--single {
              background-color: #fff;
              border: 1px solid rgba(0, 0, 0, .122);
              border-radius: 8px;
            }

            div:nth-child(1) .select2-selection--single {
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
              padding-right: 1px;
            }

            div:nth-child(2) .select2-selection--single {
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
              margin-left: -1px;
            }

          }

          .select2 {
            width: 100% !important;
          }

        }

        .carselection__car-selection-more {
          position: relative;
          z-index: 3;
          margin: 0;

          .advanced-features {

            @media screen and (max-width: 950px) {
              .grid-4 {
                grid-template-columns: repeat(3, 1fr);
              }
            }
            @media screen and (max-width: 700px) {
              .grid-4 {
                grid-template-columns: repeat(2, 1fr);
              }
            }

            @media screen and (max-width: 450px) {
              .grid-4 {
                grid-template-columns: repeat(1, 1fr);
              }
            }

            .advanced-features-block {
              margin-bottom: 30px;
              border-bottom: 1px solid #eeeeee;
              padding-bottom: 30px;

              @media screen and (max-width: 450px) {
                margin-bottom: 0px;
              }

              .checkbox {
                margin-bottom: 5px;

                label {
                  font-size: 0.9rem;
                  line-height: 1.2;

                  &:before {
                    margin-right: 10px;
                  }
                }
              }


              &:first-child {
                border-top: 1px solid #eeeeee;
                padding-top: 30px;
              }

              &:last-child {
                border: none;
              }

              .advanced-features-title {
                font-size: 1.5rem;
                margin-bottom: 30px;
              }
            }

          }

        }
      }

      .carselection__offer-request {
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translate(-50%, 62%);
        z-index: 3;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .faq-link {
          margin-top: 15px;
          color: $darkTextColor;

          &:hover {
            color: $cpuPink;
          }
        }
      }
    }
  }

}