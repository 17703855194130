.faq-container {
  h2 {
    margin-bottom: 15px;
    color: #f53bb9;
  }

  .faq {
    margin-bottom: 30px;
    p {
      text-indent: 25px;
    }
  }
}

