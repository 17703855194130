.welcome-container {
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 75%);
    background-color: #f7f7f7;
    width: 100vw;
    height: 80vh;
    max-height: 500px;
    @media screen and (max-width: 730px) {
      height: 40vh;
    }

    @media screen and (max-width: 560px) {
      height: 25vh;
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    margin-top: 5px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 75%);
    background-color: $cpuBlue;
    width: 100vw;
    height: 80vh;
    max-height: 500px;
    @media screen and (max-width: 730px) {
      height: 40vh;
    }
    @media screen and (max-width: 560px) {
      height: 27vh;
    }
  }

  .welcome {
    position: relative;
    display: grid;
    grid-template-columns: 1.2fr 0.8fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0;
    grid-row-gap: 0;
    height: 85vh;
    max-height: 550px;
    z-index: 2;
    margin-top: 50px;

    @media screen and (max-width: 820px) {
      margin-top: 0;
      max-height: 450px;
    }

    @media screen and (max-width: 730px) {
      max-height: 350px;
    }

    @media screen and (max-width: 630px) {
      height: 70vh;
    }

    @media screen and (max-width: 560px) {
      grid-template-columns: 1fr;
      max-height: 400px;
    }

    .left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      z-index: 2;
      transform: translateY(-4vh);
      @media screen and (max-width: 560px) {
        order: 1;
        margin-top: 260px;
      }

      .welcome-text {
        width: 100%;
        max-width: 520px;

        h1 {
          position: relative;
          background-color: #00BAD5;
          padding: 15px 0 10px 25px;
          width: 100%;
          color: $lightTextColor;
          font-family: 'Gilroy-Bold', serif;
          font-size: 1.9rem;
          line-height: 1.2;
          clip-path: polygon(0 0, 96% 0, 85% 100%, 0 100%);
          text-transform: uppercase;
          border-radius: 15px;
          margin-bottom: 30px;
          z-index: 2;
          @media screen and (max-width: 820px) {
            font-size: 1.5rem;
          }
          @media screen and (max-width: 730px) {
            font-size: 1.2rem;
            margin-bottom: 5px;
            padding: 10px 0 5px 15px;
            width: 90%;
          }
          @media screen and (max-width: 630px) {
            margin-bottom: 0;
          }
          @media screen and (max-width: 590px) {
            font-size: 1rem;
          }
          @media screen and (max-width: 560px) {
            clip-path: none;
            width: 70%;
          }
        }

        h2 {
          position: relative;
          font-weight: 600;
          font-size: 1.3rem;
          line-height: 1.5;
          text-transform: uppercase;
          margin: 0 0 30px 15px;
          max-width: 450px;
          z-index: 2;
          @media screen and (max-width: 820px) {
            font-size: 1.1rem;
            max-width: 350px;
          }

          @media screen and (max-width: 730px) {
            font-size: 1rem;
            max-width: 280px;
          }

          @media screen and (max-width: 630px) {
            margin-bottom: 10px;
          }
          @media screen and (max-width: 590px) {
            font-size: 0.7rem;
          }

          span {
            margin: 0;
            padding: 0;
            @media screen and (max-width: 630px) {
              width: 100px !important;
            }
          }
        }

        .btn {
          position: relative;
          z-index: 2;
        }
      }


    }

    .right {
      position: relative;

      @media screen and (max-width: 560px) {
        order: 0;
      }

      img {
        position: absolute;
        left: 35%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 650px;
        max-width: 650px;
        height: auto;
        vertical-align: middle;
        @media screen and (max-width: 820px) {
          width: 500px;
        }
        @media screen and (max-width: 730px) {
          width: 400px;
        }

        @media screen and (max-width: 630px) {
          width: 340px;
        }
        @media screen and (max-width: 560px) {
          width: 300px;
          top: 130px;
          transform: translate(-30%, -50%);
        }
      }


    }
  }
}