.modal-container {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(62, 62, 62, 0.4) !important;
  cursor: pointer;
  z-index: 999;

  .modal-item {
    box-shadow: 0 0 30px #00000012;
    border-radius: 35px;
    padding: 3rem;
    background-color: #fff;
    max-width: 1200px;
    max-height: 90%;
    overflow-y:auto ;
  }


  h1 {
    font-size: 1.9rem;
    margin-bottom: 10px;
    text-align: center;
  }

  p {
    margin: 0 auto;
  }

  .select-service {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;


    .or-separator {
      margin: 0 10px;
    }

    .form_radio_btn {
      display: inline-block;

      input[type=radio] {
        display: none;
      }

      label {
        background: #eee;
        border: 1px solid #eee;
        color: $darkTextColor;
      }

      /* Checked */
      input[type=radio]:checked + label {
        background: $cpuPink;
        color: $lightTextColor
      }

      /* Hover */
      label:hover {
        background: $cpuPinkDark;
        color: $lightTextColor
      }

      /* Disabled */
      input[type=radio]:disabled + label {
        background: #efefef;
        color: #666;
      }
    }
  }

  .calculation {
    position: relative;
    text-align: center;
    margin: 0 auto;
    max-width: 250px;
    margin-top: 30px;
    padding-bottom: 25px;

    #calculation--car-price {
      text-align: center;
      letter-spacing: 1px;
    }

    .rub_suffix:after {
      content: "₽";
      display: block;
      width: 15px;
      height: 20px;
      position: absolute;
      right: 15px;
      top: 2px;
      color: #bebebe;
      font-family: 'Gilroy-Bold', serif;
      font-size: 2rem;
      z-index: 5;
    }

    #calculation--car-price {
      font-family: 'Gilroy-Bold', serif;
      font-size: 2rem;
      color: #5e5e5e;

      &::-webkit-input-placeholder {
        color: #ccc;
        font-size: 1.4rem;
      }
    }

    #calculation--min-price {
      position: absolute;
      left: 0;
      text-align: center;
      width: 100%;
      bottom: 0;
      color: #8d8d8d;
    }

    strong {
      display: block;
      margin-bottom: 15px;
    }
  }

  .calculation-result {
    //display: none;

    .calculation-result--service {
      background: rgba(255, 249, 241, 0.7);
      border: 1px dashed rgba(0, 0, 0, 0.12);
      border-radius: 14px;
      padding: 20px;
      margin: 30px 0 15px 0;

      .calculation-result--title {
        margin-bottom: 15px;
      }

      .checkbox {
        margin-bottom: 5px;

        label {
          strong {
            padding: 0 5px;
          }
        }

      }
    }

    .faq {
      text-align: center;
    }
  }
}



