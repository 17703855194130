.lastrequest-container {
  position: relative;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    clip-path: polygon(0 20%, 100% 0, 100% 100%, 0% 100%);
    background: linear-gradient(180deg, #F5F5F5 0%, rgba(245, 245, 245, 0) 100%);
    width: 100vw;
    height: 70vh;
    z-index: 1;
  }

  .lastrequest {
    position: relative;
    z-index: 3;
    padding: 150px 0 100px 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;    gap: 30px;

    @media screen and (max-width: 900px){
      grid-template-columns: repeat(1, 1fr);
    }

    .left {
      display: flex;
      align-items: center;
      justify-content: center;

      h1 {
        color: #737373;
        text-indent: 0px;
      }
    }

    .right {
      display: flex;
      align-items: center;
      justify-content: center;
      .content {
        position: relative;
        padding: 50px;
        max-width: 450px;
        z-index: 2;

        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          transform: skewX(-4deg);
          background: $cpuPink;
          box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
          z-index: 1;
          border-radius: 15px;

        }

        h2 {
          position: relative;
          z-index: 3;
          color: $lightTextColor;
        }

        p {
          position: relative;
          z-index: 3;
          color: $lightTextColor;
          font-style: italic;
          margin-bottom: 30px;
        }

        input {
          position: relative;
          z-index: 3;
          margin-bottom: 30px;
        }

        .lastrequest__btn {
          position: absolute;
          bottom: 0;
          left: calc(50% - 10px);
          transform: translate(-50%, 50%);
          z-index: 3;
          width: 300px;
        }
      }


    }
  }
}