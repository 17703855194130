.ourservice-container {
  overflow: hidden;

  .ourservice {
    margin: 0 0 100px 0;

    .ourservice__header {
      position: relative;
      height: 120px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        clip-path: polygon(0 0%, 100% 17%, 100% 85%, 0 100%);
        background-color: $cpuBlue;
        background-image: url("images//arrow_to_right.png");
        background-repeat: no-repeat;
        background-position: 4% center;
        background-size: auto 120%;
        width: 100vw;
        height: 120px;
        z-index: 2;
      }

      &:before {
        content: "";
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 85%);
        background-color: $cpuBlueLight;
        width: 100vw;
        height: 120px;
        z-index: 2;
      }


      .ourservice__title {
        position: relative;
        text-align: center;
        z-index: 3;
        color: $lightTextColor;
        padding: 0;
        margin: 0;

      }
    }

    .ourservice__body {
      margin-top: 50px;

      @media screen and (max-width: 900px){
        grid-template-columns: repeat(1, 1fr);
        gap: 60px;
      }

      .ourservice__item {
        position: relative;
        background: #fff;
        border-radius: 25px;
        padding: 50px 30px 55px 40px;
        box-shadow: 0 20px 27px rgba(0, 0, 0, 0.05);
        transform: skewX(-3deg); /*Поворачиваем блок*/

        .ourservice__item-title {
          color: $cpuPink;
          font-size: 1.6rem;
          line-height: 1.2;
          font-family: 'Gilroy-Bold', sans-serif;
          margin-bottom: 30px;
          transform: skewX(3deg); /*Поворачиваем блок*/
        }

        .ourservice__item-body {
          text-indent: 15px;
          transform: skewX(3deg); /*Поворачиваем блок*/
          font-size: 1rem;
        }

        .ourservice__offer {
          position: absolute;
          bottom: -15px;
          left: 50%;
          transform: translateX(-50%) skewX(3deg);
          z-index: 2;
          width: 100%;


          .ourservice__offer-title {
            font-family: 'Gilroy-Bold', sans-serif;
            font-size: 1.2rem;
            text-transform: uppercase;
            text-align: center;
          }

          .ourservice__offer-price {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 10px;

            .ourservice__old-price {
              font-family: 'Gilroy-Medium', serif;
              font-size: 1.3rem;
              text-decoration: line-through;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 5px;
            }

            .ourservice__new-price {
              font-family: 'Gilroy-Bold', sans-serif;
              font-size: 1.4rem;
              line-height: 1;
              border: 2px dashed #EC51BB;
              padding: 9px 30px 5px 30px;
              color: $darkTextColor;
              border-radius: 15px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: 5px;
            }
          }

          .ourservice__offer-request {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .btn {

            }

            .btn-pink, .btn-blue {
              margin-top: 30px;
              margin-bottom: -10px;
            }

            .faq-link {
              margin-top: 30px;
              margin-bottom: -15px;
              color: $darkTextColor;

              &:hover {
                color: $cpuPink;
              }
            }
          }
        }


      }
    }
  }

}