.whatwecheck-container {
  overflow: hidden;

  .whatwecheck {
    position: relative;
    z-index: 2;
    padding-bottom: 50px;
    margin-top: 50px;

    @media screen and (max-width: 450px) {
      padding-bottom: 25px;
    }

    .whatwecheck__title {

    }

    > .whatwecheck__body {
      display: grid;
      grid-template-columns: 1.7fr 1fr;
      grid-template-rows: 1fr;
      grid-column-gap: 30px;
      grid-row-gap: 0;

      @media screen and (max-width: 900px) {
        grid-template-columns: 1fr;
      }

      .left {
        position: relative;
        padding: 40px;
        border-radius: 5px;
        @media screen and (max-width: 450px) {
          padding: 20px;
        }

        $min10: calc(-10px + 100%);

        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          clip-path: polygon(0 0, 100% 0, 100% $min10, 0 90%);
          background-color: #fff;
          width: 100%;
          height: calc(0px + 100%);
          border: 10px solid rgb(255, 170, 226);
          border-radius: 5px;
          z-index: 1;
        }

        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          margin-top: 10px;
          clip-path: polygon(0 0, 100% 0, 100% $min10, 0 90%);
          background-color: rgb(255, 170, 226);
          width: 100%;
          height: 100%;
          z-index: 1;

        }


        .whatwecheck__info-block {
          position: relative;
          z-index: 3;

          .whatwecheck__tech {
            position: relative;
            z-index: 3;
            margin-bottom: 30px;
          }

          .whatwecheck__jur {
            position: relative;
            z-index: 3;
          }

          .whatwecheck__title {
            font-family: 'Gilroy-Bold', sans-serif;
            padding: 0;
            margin: 0 0 15px 0;
            font-size: 1.5rem;
            display: flex;
            align-items: center;
            line-height: 1;

            img {
              margin-right: 15px;
              width: 50px;
            }
          }

          .whatwecheck__check-list {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto 1fr 1fr;
            grid-column-gap: 0;
            grid-row-gap: 0;
            z-index: 2;

            @media screen and (max-width: 560px) {
              grid-template-columns: 1fr;
            }

            .whatwecheck__list-item {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              font-size: 1rem;
              padding: 7px 0;

              .whatwecheck__item-icon {
                margin-right: 15px;

                img {

                  width: 32px;
                }
              }

              .whatwecheck__item-name {
                line-height: 1.2;
              }
            }
          }
        }

        .whatwecheck__offer {
          position: relative;
          z-index: 2;
          margin-top: 5px;
          margin-bottom: -70px;

          .whatwecheck__offer-title {
            font-family: 'Gilroy-Bold', sans-serif;
            font-size: 1.2rem;
            text-transform: uppercase;
            text-align: center;
          }

          .whatwecheck__offer-price {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 10px;

            .whatwecheck__old-price {
              font-family: 'Gilroy-Medium', serif;
              font-size: 1.3rem;
              text-decoration: line-through;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 5px;
            }

            .whatwecheck__new-price {
              font-family: 'Gilroy-Bold', sans-serif;
              font-size: 1.6rem;
              line-height: 1;
              background: #EC51BB;
              padding: 9px 30px 5px 30px;
              color: $lightTextColor;
              border-radius: 25px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: 5px;
            }
          }

          .whatwecheck__offer-request {
            display: block;
            margin-bottom: -30px;


            li {
              width: 100%;
              text-align: center;
              margin-bottom: 15px;
            }


            .btn {
              margin-top: 30px;
            }

            .faq-link {
              margin-top: 15px;
              color: $darkTextColor;

              &:hover {
                color: $cpuPink;
              }
            }
          }
        }


      }

      .right {
        position: relative;
        background: url("images/watermark_guaranty.png") no-repeat center;
        background-size: contain;


        .whatwecheck__info-block {
          position: relative;

          @media screen and (max-width: 900px) {
            margin-top: 250px;
          }

          .whatwecheck__guaranty {
            .whatwecheck__title {
              font-family: 'Gilroy-Bold', sans-serif;
              font-size: 1.5rem;
              margin-bottom: 15px;
            }

            .whatwecheck__body {
              font-size: 1rem;
              margin-bottom: 30px;


              p {
                text-indent: 15px;
              }
            }
          }


        }

        .whatwecheck__car {
          position: absolute;
          bottom: 0px;
          left: -100px;
          width: 550px;
          height: 365px;
          max-width: 700px;
          background: url("images/check_car.png") no-repeat center bottom;
          background-size: contain;
          z-index: 3;

          @media screen and (max-width: 900px) {
            left: 50%;
            top: 30%;
            width: 400px;
            height: 290px;
            transform: translate(-50%, -50%);
          }

          @media screen and (max-width: 450px) {
            top: 20%;
            width: 350px;
            height: 290px;
          }
        }
      }
    }

  }
}