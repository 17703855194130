.youtube-container {
  overflow: hidden;

  .youtube {
    text-align: center;
    padding: 0 0 100px 0;

    .title {
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;


      img {
        height: 35px;
        margin-left: 15px;
        margin-top: -3px;
      }
    }
  }
}